<template>
  <div>
    <el-backtop></el-backtop>
    <img src="../../assets/image/新闻资讯/资讯中部广告.png" alt=""/>
    <div class="info-content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/newsConsult' }"
          >返回 |
        </el-breadcrumb-item>
      </el-breadcrumb>
      <h1>行业新闻</h1>
      <p>
        4月25日，中国土木工程学会城市公共交通分会理事长工作会议暨5.20全国公交驾驶员关爱日启动仪式在山东省济南市顺利召开。中国土木工程学会城市公共交通分会智库专家王小磊应邀作了《公交驾驶员生命体征的智能监测技术简介》的主题演讲。
      </p>
      <p>
        王小磊从驾驶员的工作环境与压力为出发点，探索公交驾驶员生命体征的智能监测技术，以期未来可以有效降低因驾驶员健康原因造成的公交事故率。
      </p>
      <p>
        王小磊：高级工程师，中国土木工程学会城市公共交通分会智库专家，曾经任重庆市公共交通控股（集团）有限公司电车公司总工程师、BRT公司书记兼副总经理、公交维修公司总经理、恒通客车顾问、吉尔吉斯斯坦共和国史德洲（Шыдыр
        Жол Кей Джи）有限责任公司总机械师等。
      </p>
      <h2>公交驾驶员生命体征的智能监测技术简介</h2>
      <p id="author">王小磊</p>
      <h4>前言</h4>
      <p>
        近年来关于城市公交车驾驶员（以下简称：驾驶员）在岗位上突发疾病的事件频发，已有公交企业也陆续开展驾驶员的生命体征检测与管理来预防此类事件的重复发生，以确保公共交通运营安全。在“5.20”驾驶员关爱日即将来临之际，发此文就IT与传统医学理论结合对驾驶员生命体征的智能监测技术进行简要介绍，希望以此能够助推此项工作的开展。
      </p>
      <h4>1.生命体征与事故</h4>
      <p>
        百年来全球发生的最严重的新冠疫情（以下简称：疫情）对城市公交的影响是巨大的，为城市提供后勤保障服务的驾驶员，冒着被传染的风险驾驶公交车穿梭于疫区，虽然获得了全社会广泛赞誉，作为同样是普通人的驾驶员，其面对的压力更为巨大。在此条件下驾驶员因工作环境产生的生理和心理疾病也累见不鲜：今年1月25日发生在福建福清市公交车司机陈师傅，因工作时突发生理疾病（心梗），仍然忍着剧痛，安全停车，挽救了一车人的生命，自己却倒在驾驶座上再没能起来；今年的4月6号、7号更是接连两天传出公交车驾驶员在行车途中突发疾病，在失去意识的最后一刻把车安全停稳的消息；另外就是去年“7.7”的安顺公交车事故，则属典型的心理疾病所致的案例。其实，由驾驶员引发的事故并不单纯发生在公交车上，2015年3月24日，德国之翼航空公司（Germanwings）编号为4U9525的空中客车A320型客机，因飞机驾驶员的自杀行为造成150名人员遇难，是另一个因驾驶员心理疾病导致事故的典型案例。在后疫情防控的新常态背景下，总结疫情期间的经验，要保证城市生活正常运行，做好战斗在抗疫一线公交车驾驶员的健康防护及管理将纳入公交企业的管理工作。
      </p>
      <h4>2.生命体征监测的基本原理</h4>
      <p>
        为了防止驾驶员生理和心理疾病导致的行车安全事故，关注驾驶员身心健康问题已经引起业内人士的高度重视，已有公交企业运用技术手段开展对驾驶员健康的工作。其基本原理就是基于人体的生命特征参数发生变化时，根据生命体征的数据也会发生变化，通过检测设备对驾驶员生命体征的实时检测，再由智能系统进行分析后，及时采取相应的措施，力图减少或避免引发更大的事故。
      </p>
      <h5>2.1生命特征数据原理</h5>
      <p>
        人类最早发现心理状态变化，是从观察到脉搏频率变化开始的，但一直没有一个数据量化的概念。直到1875年，意大利都灵大学（University
        of Turin）医学博士、生理学教授安吉洛·莫索(AngeloMosso
        1846–1910)证明，通过测量受试者身体的变化就能通过获得的人体生命特征数据变化来判定人的行为。据此，大量现代医学试验也证明，心率、血压和情绪有明显的关系，有情绪变化时交感神经兴奋性增高，在交感神经和肾上腺素的共同作用下，一方面，心脏收缩加强、加快，心输出量增多；另一方面，身体大部分区域的小血管收缩，外周阻力增大。体征参数即为心率加快，血压升高。这说明,心理社会因素的刺激影响人的心理状态,而人的心理状态的好坏又与心率、血压有着直接的关系。根据这个原理，如果检测到血压、心率的变化率异常，即可判断人的心理状态出现变化。
      </p>
      <h5>2.2 穿戴设备</h5>
      <p>
        对驾驶员生命体征与行车安全行为的监测是交通行业一直在研究的课题。曾经有人在驾驶员的头部安装检测贴片、在安全带上安装传感器件、或将方向机与传感器结合用于监测驾驶员生命体征的监测设备等方法。这些设备对驾驶员行为的影响较大，而且受传感器和通信技术发展的限制，所得数据存在不稳定性，给准确监测带来干扰，未能取得满意的成果。1982年日本精工（Seiko）通过其收购的公司推出了一款可编程的手环之后，在计算机和互联网技术发展的推动下，作为一种穿戴式智能设备（如：运动手环和手表等），使血压、心率等生命体征的检测变得容易。
      </p>
      <h5>2.3 穿戴设备数据检测原理</h5>
      <p>
        穿戴设备可用于生命体征检测的项目较多，用于驾驶员生命体征监测的主要是基于光电容积脉搏波(Photo
        plethysmography, PPG)信号和心电描记法(Electrocardiography，ECG)
        数据的测量，本文在此对其数据检测方法的原理进行简单的介绍。
      </p>
      <h6>2.3.1 心率</h6>
      <b>1)光电容积脉搏波描记法（PPG）原理</b>
      <p>
        光电容积脉搏波描记法是目前穿戴设备用来检测心率用得最多的方法，其依据的原理是一束固定波长、绿色的LED光束（因为绿光在红色的血液中吸收率是最大的，所以用绿光检测对数据判断就最准确。）照射到皮肤表面时，光束将通过透射或反射方式把光传送到光电接收器，在这个过程中由于受到皮肤肌肉和血液的吸收造成衰减，检测器检测到的光强度将减弱。而皮肤、肌肉组织等对光的吸收在整个血液循环中是保持恒定不变的，但皮肤内的血液容积会在心脏的作用下呈搏动性变化。当心脏收缩时外周血容量最多，光吸收量也最大，检测到的光强度就最小。而在心脏舒张时，则反之，检测到光强度最大，使光接收器接收到的光强度随之呈脉动性变化。再将此光强度变化信号转换成电信号，将此电信号经放大器后便可获得容积脉搏血流的变化。
      </p>
      <b>2）心电描记法（ECG）原理</b>
      <p>
        在心肌细胞处于静息状态下时，心肌细胞膜两侧存在由正负离子浓度差形成的电势差，随着心脏的跳动，心肌细胞电势差迅速向0变化。这过程被称为去极化，在此期间会产生微小的电压变化。每次心跳，人的体表都会有微小的电极变化，捕捉到这些电极变化，再经过算法就可以还原出心率跳动的频率。穿戴设备采用的测量技术，就是用人体的电位差进行心率检测的心电法。心电描记法其实与医院里面做心电图类似，心脏在每个心动周期中，心脏周围的组织和体液都能导电，因此可将人体看成为一个具有长、宽、厚三度空间的容积导体。心脏好比电源，每次心跳都伴随着无数心肌细胞动作，心肌细胞的动作使人的体表产生微小的生物电变化称为心电，而捕捉到这些电极变化，再经过算法就可以还原出心率跳动的频率。
      </p>
      <h6>2.3.2 血压</h6>
      <p>
        穿戴设备用于血压检测的“PPG+ECG”法是一种间接测量方法。即通过传感器采集手腕部位的PPG和ECG信号结合起来分析，可以发现ECG的峰值来自于心室的收缩，而PPG的峰值则是因为血管收缩所造成的，因此可以得到血液自心脏送出后到达量测部位的传输时间，从心脏搏动开始，血流流到测试处（如手腕）的时间差，也就是脉搏波传递时间Pulse
        Transit Time
        (PTT)。因为心电信号传播采集原理上是光速，传播时间可以忽略为0，PTT则主要受血流速度（Pulsewave
        velocity
        ,PWV）的影响，PWV与血压是直接相关的，血压高时，PWV快，反之则慢。因为在光电传感器的基础上再加上心电信号收集到的数据，然后再进行综合分析，这是目前穿戴设备使用的相对精确的血压检测方法。
      </p>
      <h6>2.3.3 疲劳度</h6>
      <p>
        目前的疲劳驾驶检测多是通过摄像头抓取驾驶人脸部图像，通过图像识别算法识别驾驶人眼部特征，判断有无瞌睡行为。此种方法往往有时间滞后，检测从闭眼到提醒驾驶人睁眼，至少需要0.7秒，但恰恰是这0.7秒中间，已经失去驾驶员采取紧急制动时所需的最佳时机，监测系统提醒的延迟可能导致驾驶员操作行为的滞后，严格来讲起不到预防的作用。如果从生命体征的角度分析，人的精神疲劳主要表现为疲惫感，注意力难于集中，对事物的敏感性和警戒性降低，大脑兴奋度降低等等。在生理方面，心率加快会升高血压，心率变化比较明显。临床实验证明，脉搏信号包含着丰富的人体生理信息，通过对脉搏信息的分析处理可以获取反映生命体征的指标，可实现对精神疲劳的诊断与程度分级。实验对于中医脉诊客观化，避免疲劳驾驶预防事故发生具有重要而积极的意义。为了提高驾驶员疲劳检测的可靠性，在实际运用中，可将驾驶员脸部图像识别与PPG的实时数据，用人工智能技术来综合分析出人体的疲劳反应生理情况的阈值，从而建立一个能够快速判别的疲劳模型，实时评估驾驶员是否处于疲劳驾驶状态并及时采取相应的措施。
      </p>
      <h5>2.4 关于准确性</h5>
      <h6>2.4.1 血压</h6>
      <p>
        血压检测最准确的是有创插入法，但其方便性受到使用条件限制。目前临床医学广泛使用的水银血压计和电子血压计是基于1905年发明的柯氏音法（Коротков
        Звуки），它是通过袖带加压和听脉搏音来测量血压，解决了无创测压的方法；电子血压计（电子柯氏音法）则是把人工柯氏音法用电子技术来完成。也就是对袖带加气、放气用气泵来完成。听脉搏音用电子拾音器来完成，计算机代替了人的判断方法，读数直接显示，现在已经被医疗行业大量广泛采用。目前穿戴设备测量血压的准确性较上述传统的血压测量方法要差，并未达到《JJG
        692-2010
        无创自动测量血压计检定规程》标准，但不影响对生命体征数据变化率的监测，不是作为医疗器械使用的。
      </p>
      <h6>2.4.2 阈值</h6>
      <p>
        驾驶员的生命体征检测不是医疗检测，属于健康监测的一种方式，通过对其采集的大量数据，再由AI建立的模型进行统计分析后，确定该驾驶员出现突变生命体征的数据变化率阈值，通过阈值来判定该驾驶员是否出现生命体征异常或疾病，是否需要离岗观察或进一步的体检，尽可能的避免安全事故的发生。因此所采集数据的重点是用来对生命体征数据变化率分析，而不单纯的是一组参数大小。
      </p>
      <h4>3. 生命体征的监测</h4>
      <h5>3.1 岗前</h5>
      <p>
        驾驶员上班到达车队，在进行车辆例行保养前，调度值班室（或休息室）的信息采集终端会自动与驾驶员佩戴的穿戴设备连接并采集数据（签到），后台（车队和公司管理部门）即开始录入当天驾驶员工作的健康档案，并评估所有生命体征数据，若不符合标准，将不能签到；如果符合标准，驾驶员即可进入当班车辆进行到岗签到，车载智能终端与驾驶员的穿戴设备也是自动进行无线通信连接的，在对车辆进行例行保养之后，智能终端将告知驾驶员可以投入运营。
      </p>
      <h5>3.2 行车</h5>
      <p>
        行车过程中，鉴于车载智能终端回传车辆数据都有一个间隔期，正常情况下驾驶员的实时生命体征检测数据是存入车载智能存储器内，然后打包回传至后台。若穿戴设备检测到驾驶员的生命体征参数超过安全管理的阈值，除了在车载智能终端会发出警报，还会立即通过车载智能终端对车辆采取安全控制措施，并及时通知后台。在客运低谷时期，车辆在停车点处于待班调停时（20分钟以上），驾驶员的生命体征参数如果不能恢复到正常状态，穿戴设备也会报警，提醒有关管理人员关注驾驶员的健康状态。
      </p>
      <h5>3.3 下班</h5>
      <p>
        运营车驾驶员行驶完当天的最后里程后，在做完下班前进行交班的车辆例行检查之后（以车载终端的信息为准），要进入现场调度值班室（或休息室）由采集设备通过无线通信采集驾驶员下班时的生命体征数据（签退）作为工作岗位全天健康档案的最后一部分存储起来。
      </p>
      <h5>3.4 数据应用</h5>
      <p>
        公共交通的现场管理实际上就是管“两个头”，一个是公交车辆管理—“车头”；另一个就是驾驶员—“人头”。在相关法律允许的范围内，驾驶员的生命体征监测数据，是企业ERP的重要组成部分和作为对驾驶员健康智能化管理的基础。并且将作为企业人力资源质量重要评估指标之一，公司管理机构可以此作为制订生产计划的依据，是企业关爱的员工的实事之一。
      </p>
      <h4>4. 小结</h4>
      <p>
        城市公交车常年在城市复杂的运营环境行驶，驾驶员工作时劳动强度大，要耗费大量的精力，大脑会处于长期的超负荷运行，并且通常情况下，驾驶员的生理、心理及疲劳等疾病状态不伴有明显的病理表现，常规条件下不易被发现。驾驶员生命体征的智能监测与管理，是关注驾驶员的身心健康、维护社会公共交通安全的保障措施，应成为公交企业的常态化工作之一。但是生命体征的智能化管理是一项复杂的系统技术，还处于发展和不断的完善之中。随着医学理论与可穿戴设备技术的持续发展，驾驶员生命体征的检测精度将逐步向医疗级靠拢，并将普及成为提高公交驾驶员管理的标配，以尽可能从根本上消除安全行车及其他隐患。真正做到“车有问题不上路，人有疾病不行车”，为公众的平安出行提供安全、优质、便捷、高效的保障。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.push('/newsConsult')
    }
  }
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  display: inline-block;
}
h1 {
  display: inline-block;
  font-size: 40px;
  color: #333;
  margin: 0 0 50px 30px;
  border-bottom: 2px solid #3468d2;
}
.info-content {
  margin: 82px 160px 60px;
}
h2 {
  font-size: 30px;
  line-height: 50px;
  color: #111;
  text-align: center;
  margin: 15px 0;
}
h4 {
  font-size: 25px;
  line-height: 40px;
  color: #333;
  margin: 15px 0;
}
h5 {
  font-size: 22px;
  line-height: 40px;
  color: #333;
  margin: 12px 0;
}
h6 {
  font-size: 20px;
  line-height: 35px;
  color: #333;
  margin: 10px 0;
}
b {
  font-size: 18px;
  line-height: 30px;
  color: #333;
  margin: 10px 0;
}
#author {
  text-align: center;
}
p {
  font-size: 18px;
  line-height: 30px;
  text-indent: 2em;
  color: #333;
  margin: 10px 0;
}
.el-page-header {
  width: 200px;
  margin: 50px 0;
}
.el-page-header__content::after {
  content: '';
  display: block;
  width: 4%;
  height: 2px;
  margin-top: 8px;
  background-color: red;
}
.intro {
  margin-top: 20px;
  font-size: 30px;
  line-height: 72px;
  color: #333;
  text-indent: 2em;
}
</style>
